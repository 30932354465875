/* Tooltips
/* ------------------------- */


// Base class
.tooltip {
	font-size: 9px;
	text-transform: uppercase;
	font-family: $font-family-accent;
	font-weight: 700;

	&.show {
		opacity: $tooltip-opacity;
	}
}

// Wrapper for the tooltip content
.tooltip-inner {
	padding: 5px 10px;
	color: $tooltip-color;
	text-align: center;
	background-color: $tooltip-bg;
	border-radius: 2px;

	@if $template == 'football' {
		box-shadow: 0 0 12px rgba(0,0,0,.3);
	}
}


.bs-tooltip-top {

	.arrow {

		&::before {
			border-top-color: $tooltip-arrow-color;
		}
	}
}

.bs-tooltip-right {

	.arrow {

		&::before {
			border-right-color: $tooltip-arrow-color;
		}
	}
}

.bs-tooltip-bottom {

	.arrow {

		&::before {
			border-bottom-color: $tooltip-arrow-color;
		}
	}
}

.bs-tooltip-left {

	.arrow {

		&::before {
			border-left-color: $tooltip-arrow-color;
		}
	}
}
