/* Progress Bars
/* ------------------------- */

// Bar itself
// -------------------------
.progress-stats {
	overflow: hidden;
	position: relative;
	margin-bottom: 19px;
	display: flex;
	align-items: center;

	.progress {
		flex-grow: 1;
		margin-bottom: 0;
	}
}

// Outer container
.progress {
	overflow: hidden;
	height: 6px;
	margin-bottom: 10px;
	background-color: $progress-bg;
	border-radius: $progress-border-radius;
	box-shadow: none;
	position: relative;
	top: 2px;
}

	// Bar of progress
	.progress__bar {
		float: left;
		width: 0%;
		height: 100%;
		line-height: 6px;
		color: $progress-bar-color;
		background-color: $progress-bar-bg;
		box-shadow: none;
		border-radius: $progress-border-radius;
	}

	.progress__label,
	.progress__number {
		@include font-accent(11px);
		line-height: 1em;
	}

	.progress__label {
		color: $progress-label-color;

		& + .progress {
			margin-left: 10px;
		}

		&.progress__label--color-default {
			color: $body-font-color;
		}
	}
	.progress__number {
		font-weight: normal;

		&.progress__number--color-highlight {
			color: $headings-color;
		}

		.progress + & {
			margin-left: 10px;
		}
	}

	.progress__label-top {
		display: block;
		text-align: right;
	}

.progress__bar--secondary {
	@include progress-bar-variant($progress-bar-secondary-bg);
}

.progress__bar--success {
	@include progress-bar-variant($progress-bar-success-bg);
}

.progress__bar--info {
	@include progress-bar-variant($progress-bar-info-bg);
}

.progress__bar--warning {
	@include progress-bar-variant($progress-bar-warning-bg);
}

.progress__bar--danger {
	@include progress-bar-variant($progress-bar-danger-bg);
}

.progress__bar--danger {
	@include progress-bar-variant($progress-bar-danger-bg);
}

.progress__bar--aqua-gradient {
	@include progress-bar-variant-gradient(#0683d3, #3ffeca);
}


// Double Progress
.progress-double-wrapper {

	& + & {
		margin-top: 12px;
	}

	.progress--battery {
		margin-bottom: 0;
	}
}

.progress-double-wrapper--fullwidth {

	.progress--battery {

		// Remove bar holder stripes
		background-image: none !important;
	}
}

.progress-inner-holder {
	display: table;
	width: 100%;
	padding-bottom: 3px;
}
	.progress__digit {
		display: table-cell;
		width: 1%;
		vertical-align: middle;
		@include font-accent(11px);
		line-height: 1.2em;
		font-weight: 400;
	}
	.progress__digit--40 {
		width: 40px;

		&.progress__digit--left {
			padding-right: 0;
		}

		&.progress__digit--right {
			text-align: right;
			padding-left: 0;
		}
	}
	.progress__digit--38 {
		width: 38px;

		&.progress__digit--left {
			padding-right: 0;
		}

		&.progress__digit--right {
			text-align: right;
			padding-left: 0;
		}
	}
	.progress__digit--highlight {
		color: $progress-label-color;
	}
	.progress__digit--left {
		padding-right: 20px;
	}
	.progress__digit--right {
		padding-left: 20px;
	}
		.progress-title {
			font-size: 10px;
			line-height: 1.2em;
			margin-bottom: 0.6em;
			text-align: center;
		}

	.progress__double {
		display: table-cell;

		.progress {
			width: 50%;
			float: left;
			margin-bottom: 0;
			top: 3px;

			&:first-child {
				border-radius: $progress-border-radius 0 0 $progress-border-radius;

				.progress__bar {
					margin-left: auto;
					border-radius: $progress-border-radius 0 0 $progress-border-radius;
				}
			}

			&:last-child {
				border-radius: 0 $progress-border-radius $progress-border-radius 0;

				.progress__bar {
					border-radius: 0 $progress-border-radius $progress-border-radius 0;
				}
			}

			&.progress--battery {

				&:first-child {
					transform: scale(-1, 1);
					margin-right: 2px;
					width: calc(50% - 2px);

					.progress__bar {
						// float: left;
						margin-left: 0;
					}
				}

				&:last-child {
					margin-left: 2px;
					width: calc(50% - 2px);
				}
			}
		}
	}

	// Label in the Center
	.progress__double--label-center {

		.progress-title {
			float: left;
			width: 38%;
		}

		.progress {
			width: 31%;
		}
	}


// Progress Top Label
.progress-stats--top-labels {
	flex-wrap: wrap;
	justify-content: space-between;
	overflow: visible;

	.progress__label {
		order: 0;
	}
	.progress {
		flex-basis: 100%;
		order: 2;
		margin-left: 0 !important;
		margin-top: 12px;
		margin-bottom: 10px;
	}
	.progress__number {
		order: 1;
		font-weight: 700;
	}
}

// Sizes
.progress--lg {
	height: 9px;
	border-radius: 5px;

	.progress__bar {
		border-radius: 5px;
	}

	.progress__double {

		.progress {

			&:first-child {
				border-radius: 5px 0 0 5px;

				.progress__bar {
					border-radius: 5px 0 0 5px;
				}
			}

			&:last-child {
				border-radius: 0 5px 5px 0;

				.progress__bar {
					border-radius: 0 5px 5px 0;
				}
			}
		}
	}
}


/* Progress - */
.progress--battery {
	height: 20px;
	margin-bottom: 18px;
	top: 0;
	border-radius: 0;
	@include progress-bar-battery-holder($progress-bg);

	// Primary
	.progress__bar {
		line-height: 20px;
		border-radius: 0;
		position: relative;
		z-index: 1;
		@include progress-bar-battery-bar($progress-bar-bg);
	}

	// Primary
	.progress__bar--secondary {
		line-height: 20px;
		border-radius: 0;
		position: relative;
		z-index: 1;
		@include progress-bar-battery-bar($progress-bar-secondary-bg);
	}

	// Success
	.progress__bar--success {
		@include progress-bar-battery-bar($progress-bar-success-bg);
	}

	// Info
	.progress__bar--info {
		@include progress-bar-battery-bar($progress-bar-info-bg);
	}

	// Warning
	.progress__bar--warning {
		@include progress-bar-battery-bar($progress-bar-warning-bg);
	}

	// Danger
	.progress__bar--danger {
		@include progress-bar-battery-bar($progress-bar-danger-bg);
	}
}



// For Demo
.progress__bar-width-100 {
	width: 100%;
}
.progress__bar-width-90 {
	width: 90%;
}
.progress__bar-width-80 {
	width: 80%;
}
.progress__bar-width-70 {
	width: 70%;
}
.progress__bar-width-60 {
	width: 60%;
}
.progress__bar-width-50 {
	width: 50%;
}
.progress__bar-width-40 {
	width: 40%;
}
.progress__bar-width-30 {
	width: 30%;
}
.progress__bar-width-20 {
	width: 20%;
}
.progress__bar-width-10 {
	width: 10%;
}
