/* Widget: Games History */
.widget-games-history {

}
  .games-history-chart {

  }

  .chart-legend {

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      @include font-accent(9px);
      color: $headings-color;

      > li {
        display: inline-block;
        padding-left: 20px;

        &:first-child {
          padding-left: 0;
        }

        > span {
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 2px;
          margin-right: 10px;
        }
      }
    }
  }
