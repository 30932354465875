/*
// #Info Block
// --------------------------------------------------
*/

.info-block {
  list-style: none;
  padding: 0;
  margin: 0;
}
  .info-block__item {
    position: relative;
    min-height: 26px;
    padding: 2px 0 0 40px;
    margin-bottom: 34px;
    list-style: none;
    text-align: left;

    &:last-child {
      margin-bottom: 0;
    }

    .df-icon,
    .df-icon-stack {
      position: absolute;
      left: 0;
      top: 0;
    }

    // Custom Icon
    .df-icon--custom {
      height: auto;
      font-size: 28px;
      line-height: 1.2em;
      color: $info-block-icon-color;
      
      img {
        vertical-align: top;
      }
    }

    .header__primary & {
      text-align: left;
      margin: 20px 0;
      padding: 2px 30px 16px 70px;

      &.info-block__item--contact-primary {
        margin-top: 36px;
      }

      &.info-block__item--contact-secondary {
        margin-bottom: 0;
      }

      .df-icon {
        left: 30px;
      }

      .info-block__link {
        padding: 0;
        font-size: 9px;
        line-height: 1.2em;
        color: $header-info-block-link-color-mobile;
        border: none;

        // Hide left border on hover
        &::before {
          display: none;
        }

        &:hover {
          color: #fff;
        }
      }

      @media (min-width: 992px) {
        display: none;
      }
    }
  }
  .info-block__item--nopadding {
    padding-left: 0;
  }

  @if $template == football {
    .info-block__item--helmet {
      padding-left: 50px;
    }
    .info-block__item--contact-primary {
      padding-left: 50px;
    }
    .info-block__item--contact-secondary {
      padding-left: 45px;
    }
  }

    .info-block__heading {
      font-size: 9px;
      line-height: 1.2em;
      margin-bottom: 5px;
      color: #fff;
      text-transform: uppercase;

      @if $template == football {
        font-size: 10px;
      }
    }
    .info-block__link {
      display: block;
      text-transform: uppercase;
      font-family: $font-family-accent;
      font-size: 9px;
      line-height: 1.2em;
      color: $info-block-link-color;
      vertical-align: top;
      transition: color 0.25s cubic-bezier(0.3, 0.1, 0.55, 1);

      @if $template == football {
        font-size: 10px;
      }

      &:hover {
        color: #fff;
      }
    }
    .info-block__link-wrapper {
      display: block;
    }

// Info Block inside Header
.info-block--header {
  display: inline-block;
  vertical-align: middle;

  .info-block__item {
    display: inline-block;
    margin: 7px 0 0 26px;

    @media (min-width: 992px) {
      padding-top: 20px;
      padding-bottom: 32px;

      .df-icon {
        top: 24px;
        stroke: $header-info-block-color;
      }
      .df-icon-stack--bag {
        top: 3px;

        .df-icon--bag-handle {
          top: 19px;
        }
      }

      .info-block__heading {
        margin-top: 5px;
      }
    }

    @media (min-width: 992px) and (max-width: 1199px) {
      margin-left: 20px;
    }

    &.has-children {

      > .info-block__link-wrapper {
        padding-right: 16px;

        &::after {
          content:"";
          position: absolute;
          right: 0;
          top: 29px;
          width: 6px;
          height: 4px;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 6 4'%3E%3Cpath transform='translate(-586.156 -1047.28)' fill='%23fff' d='M586.171,1048l0.708-.71,2.828,2.83-0.707.71Zm4.95-.71,0.707,0.71L589,1050.83l-0.707-.71Z'/%3E%3C/svg%3E");
          background-size: 6px 4px;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }

      &:hover {

        .header-cart {

          transform: rotateX(0deg);
          opacity: 1;
        	visibility: visible;
        }
      }
    }
  }

  .info-block__item--shopping-cart {

    .info-block__heading {
      margin-bottom: 3px;
    }
  }
    .info-block__cart-sum {
      font-size: 11px;
      text-transform: uppercase;
      font-family: $font-family-accent;
      line-height: 1.2em;
      display: block;
      color: $header-info-block-cart-sum-color;
    }

  .header-cart {
    list-style: none;
    padding: 0;
    margin: 0;
    display: block;
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 99;
    width: $header-cart-width;
    background-color: $header-cart-bg;
    opacity: 0;
    transform-origin: 0% 0%;
    transform-style: preserve-3d;
    transform: rotateX(-75deg);
    visibility: hidden;
    transition: transform 0.3s, opacity 0.3s;
  }
    .header-cart__item {
      position: relative;
      overflow: hidden;
      padding: 24px 24px 19px 24px;
      border-top: 1px solid $header-cart-border;
    }
      .header-cart__inner {
        overflow: hidden;
        padding: 5px 0 0 0;
      }
        .header-cart__product-thumb {
          float: left;
          margin: 0 12px 0 0;
          border-radius: $header-cart-thumb-border-radius;

          img {
            max-width: 100%;
            height: auto;
            transition: opacity 0.2s ease;
            border-radius: $header-cart-thumb-border-radius;

            &:hover {
              opacity: 0.7;
            }
          }
        }
        .header-cart__product-cat {
          display: block;
					font-size: 8px;
					@include fix_small_chars();
          line-height: 1.2em;
          font-weight: 700;
          text-transform: uppercase;
          font-family: $font-family-accent;
          margin-bottom: .2em;
        }
        .header-cart__product-name {
          font-size: 12px;
          line-height: 1.2em;
          margin-bottom: .5em;
          font-family: $font-family-accent;
          text-transform: uppercase;
          font-style: $header-cart-product-name-font-style;

          > a {
            color: $header-cart-product-name-color;
            transition: color 0.3s ease;

            &:hover {
              color: $header-cart-product-name-color-hover;
            }
          }
        }
        .header-cart__product-ratings {
          font-size: 10px;
          line-height: 10px;
          margin-bottom: 2px;

          .fa {
            margin-right: 2px;
            color: $header-cart-product-rating;

            &.empty {
              color: $header-cart-product-rating-empty;
            }
          }
        }
        .header-cart__product-sum {
          font-size: 12px;
          font-weight: 700;
          font-family: $font-family-accent;
          color: $header-cart-product-sum-color;
        }
          .header-cart__product-price {

          }
          .header-cart__product-count {

          }
        .header-cart__close {
          display: block;
          position: absolute;
          right: 18px;
          top: 12px;
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          color: $header-cart-product-close;
          opacity: .4;
          font-size: 14px;
          transition: opacity 0.2s ease;

          &:hover {
            opacity: 1;
            cursor: pointer;
          }
        }

    .header-cart__item--subtotal {
      overflow: hidden;
      font-size: 11px;
      line-height: 1.3em;
      padding-top: 21px;
      padding-bottom: 21px;
      text-transform: uppercase;
      color: $header-cart-subtotal-label;
      font-weight: 700;
      font-family: $font-family-accent;
    }
      .header-cart__subtotal {
        float: left;
      }
      .header-cart__subtotal-sum {
        float: right;
        color: $header-cart-subtotal-sum;
      }

    // Cart Action Buttons
    .header-cart__item--action {
      padding-top: 16px;
      padding-bottom: 16px;

      .btn {
        width: 48%;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        margin-top: 0;
        transition: background-color 0.3s ease;

        &:first-child {
          float: left;
        }

        &:last-child {
          float: right;
        }
      }
    }
}

// Info Block inside Footer Widget

.footer-widgets {

  .info-block__item {

    .df-icon {
      stroke: $footer-contact-info-icon-color;
    }
  }
}

// Info Block Horizontal
.info-block--horizontal {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  @media (min-width: 992px) {
    flex-wrap: nowrap;
    width: auto;
  }

  .info-block__item {
    width: 100%;
    padding-left: 50px;

    @media (min-width: 768px) {
      width: 33.3%;
    }

    @media (min-width: 992px) {
      width: auto;
      margin: 0 20px;
    }

    @media (min-width: 1200px) {
      margin: 0 43px;
    }

    &:last-child {

      @media (max-width: 991px) {
        margin-bottom: 34px;
      }
    }
  }

  .info-block__item--social {
    padding-left: 0;
  }
}
