/* Countdown
/* ------------------------- */
.countdown {

}
  .countdown__title {

  }
  .countdown__content {
    padding: 28px 0 5px 0;
  }

  .countdown-counter {
    overflow: hidden;
  }
    .countdown-counter__item {
      font-size: 26px;
      line-height: 1.2em;
      text-transform: uppercase;
      color: $headings-color;
      font-family: $font-family-accent;
      font-weight: 700;
      letter-spacing: -0.02em;
      text-align: center;
      float: left;
      width: 25%;
      position: relative;

      & + & {

        &::before {
          content:":";
          display: block;
          position: absolute;
          left: 0;
          top: -3px;
          font-size: 26px;
          line-height: 1.2em;
          font-weight: normal;
        }
      }
    }
    .countdown-counter__label {
      display: block;
      font-size: 10px;
      line-height: 1.2em;
      padding-top: 3px;
      color: $body-font-color;
    }
