/*
// #Content
// --------------------------------------------------
*/

.site-content {
  padding: 30px 0;

  @media (min-width: 992px) {
    padding: 60px 0;
  }
}
